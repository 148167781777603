<template>
  <div v-if="!userData">
    <h1>圖奇自主訂閱數量即時計算</h1>
    <p>此網頁透過即時下載訂閱者清單，並計算其中自主訂閱的人數。不儲存任何資料，請放心使用。</p>
    <a class="btn btn-primary" @click="handleToken" href="https://id.twitch.tv/oauth2/authorize?client_id=d7gt7r9lxyr7rbi1iw7pjhowcti949&force_verify=true&redirect_uri=https://jp.pyeweb.com&response_type=token&scope=openid+user:read:email+channel:read:subscriptions">提供授權</a>
  </div>
  <div v-else>
  <h1>你的帳號資訊</h1>
  <p>UID:{{ userData.id }}</p>
  <p>Twitch帳號:{{ userData.login }}</p>
  <p>暱稱:{{ userData.display_name }}</p>
  <p>電子信箱:{{ userData.email }}</p>
  <p>創立帳號時間:{{ userData.created_at }}</p>
   <p>帳號類型(affiliate為盟友，partner為夥伴):{{ userData.broadcaster_type }}</p>
  <img :src="userData.profile_image_url" alt="大頭貼">
  <button class="btn btn-primary" @click="fetchSubscriptions">點我取得訂閱者清單</button>
  <p v-if="subscriptionCount !== null">自主訂閱仔: {{ subscriptionCount }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            userData: null,
            accessToken: null,
            subscriptionCount: null
        }
    },
    methods: {
        handleToken() {
            const urlParams = new URLSearchParams(window.location.hash.substring(1));
            const accessToken = urlParams.get('access_token');
            // Do something with the access token (e.g., store it or perform API requests)
            if (accessToken) {
                this.accessToken = accessToken;
                this.fetchUserData(accessToken);
            }
        },
        fetchUserData(accessToken) {
            const headers = {
                'Client-ID': 'd7gt7r9lxyr7rbi1iw7pjhowcti949',
                'Authorization': `Bearer ${accessToken}`
            };
            console.log(accessToken);
            axios.get('https://api.twitch.tv/helix/users', {headers})
            .then(response => {
                console.log(response.data);
                this.userData = response.data.data[0];
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                console.log('Twitch Userdata get complete.')
            })
        },
        fetchSubscriptions() {
            let subscriptionCount = 0;
            let cursor = '';
            const accessToken = this.accessToken;
            console.log(accessToken);

            const fetchPage = () => {
                const headers = {
              'Client-ID': 'd7gt7r9lxyr7rbi1iw7pjhowcti949',
              'Authorization': `Bearer ${accessToken}`
            };
            console.log(accessToken);
              axios.get(`https://api.twitch.tv/helix/subscriptions?broadcaster_id=${this.userData.id}&first=100${cursor ? '&after=' + cursor : ''}`, { headers })
                .then(response => {
                  const subscriptions = response.data.data;
                  subscriptionCount += subscriptions.filter(sub => !sub.is_gift).length;

                  const pagination = response.data.pagination;
                  if (pagination && pagination.cursor) {
                    cursor = pagination.cursor;
                    fetchPage(); // Fetch next page recursively
                  } else {
                    this.subscriptionCount = subscriptionCount;
                  }
                })
                .catch(error => {
                  console.log(error);
                });
            };

            fetchPage(); // Start fetching the first page
    }
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.hash.substring(1));
        const accessToken = urlParams.get('access_token');
        if (accessToken) {
            this.accessToken = accessToken;
            // Token is present, handle it
            this.fetchUserData(accessToken);
        } 
    }

}
</script>

<style >
.box { background-color: var(--bs-tertiary-bg); }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  data-bs-theme: dark;
}
</style>
